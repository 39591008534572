<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增分机')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <!-- <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="基本设置" name="first"> -->
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('分机号')" prop="dn">
              <el-input v-model="ruleForm.dn" :placeholder="$t('分机号')" maxlength="8"
                        onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('密码')" prop="password">
              <el-input v-model="ruleForm.password" :placeholder="$t('密码')" maxlength="16" show-password
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="display_name">
              <el-input v-model="ruleForm.display_name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('职务')">
              <el-input v-model="ruleForm.duty" :placeholder="$t('职务')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
          <!--            <el-form-item :label="$t('中继')" prop="trunk_id">-->
          <!--              <el-select v-model="ruleForm.trunk_id" filterable clearable :placeholder="$t('请选择中继')">-->
          <!--                <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
          <!--            <el-form-item :label="$t('出局外显')">-->
          <!--              <el-input v-model="ruleForm.trunk_number" :placeholder="$t('出局外显')" :disabled="ruleForm.trunk_id === ''" maxlength="32" clearable></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('部门')">
              <el-cascader v-model="ruleForm.parentId" :placeholder="$t('请选择部门')" :options="data"
                           :props="defaultProps"
                           :show-all-levels="false"
                           clearable filterable/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('权限')" prop="call_authority">
              <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择权限')">
                <el-option :label="$t('禁止')" value="0"></el-option>
                <el-option :label="$t('局内')" value="1"></el-option>
                <el-option :label="$t('本地')" value="2"></el-option>
                <el-option :label="$t('国内')" value="4"></el-option>
                <el-option :label="$t('国际权限')" value="8"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('录音')">
              <el-switch v-model="ruleForm.isRecord" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio label="0">{{ $t('普通分机') }}</el-radio>
                <el-radio label="1">{{ $t('队列分机') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- </el-tab-pane>
        <el-tab-pane label="高级设置" name="second">
          <el-tabs tab-position="left">
            <el-tab-pane label="无应答转接">
              <el-form :model="response" size="small" label-width="90px">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="状态">
                      <el-switch v-model="response.status"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="等待时间(s)">
                      <el-input v-model="response.latencyTime" placeholder="等待时间(s)" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="转接号码">
                      <el-input v-model="response.transferNumber" placeholder="转接号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="呼转外显">
                      <el-input v-model="response.turnExplicit" placeholder="呼转外显" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
                    <el-form-item label="星期">
                      <el-checkbox v-model="response.checked1" label="星期一"></el-checkbox>
                      <el-checkbox v-model="response.checked2" label="星期二"></el-checkbox>
                      <el-checkbox v-model="response.checked3" label="星期三"></el-checkbox>
                      <el-checkbox v-model="response.checked4" label="星期四"></el-checkbox>
                      <el-checkbox v-model="response.checked5" label="星期五"></el-checkbox>
                      <el-checkbox v-model="response.checked6" label="星期六"></el-checkbox>
                      <el-checkbox v-model="response.checked7" label="星期日"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
                    <el-form-item label="时间">
                      <el-time-picker is-range v-model="response.value1" range-separator="至" start-placeholder="开始时间"
                        end-placeholder="结束时间" placeholder="选择时间范围">
                      </el-time-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="遇忙转接">
              <el-form :model="busy" size="small" label-width="90px">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="状态">
                      <el-switch v-model="busy.status"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="等待时间(s)">
                      <el-input v-model="busy.latencyTime" placeholder="等待时间(s)" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="转接号码">
                      <el-input v-model="busy.transferNumber" placeholder="转接号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="呼转外显">
                      <el-input v-model="busy.turnExplicit" placeholder="呼转外显" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
                    <el-form-item label="星期">
                      <el-checkbox v-model="busy.checked1" label="星期一"></el-checkbox>
                      <el-checkbox v-model="busy.checked2" label="星期二"></el-checkbox>
                      <el-checkbox v-model="busy.checked3" label="星期三"></el-checkbox>
                      <el-checkbox v-model="busy.checked4" label="星期四"></el-checkbox>
                      <el-checkbox v-model="busy.checked5" label="星期五"></el-checkbox>
                      <el-checkbox v-model="busy.checked6" label="星期六"></el-checkbox>
                      <el-checkbox v-model="busy.checked7" label="星期日"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
                    <el-form-item label="时间">
                      <el-time-picker is-range v-model="busy.value1" range-separator="至" start-placeholder="开始时间"
                        end-placeholder="结束时间" placeholder="选择时间范围">
                      </el-time-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="外线号码">
              <el-form :model="perimeter" size="small" label-width="90px">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="外线号码">
                      <el-input v-model="perimeter.perimeterNumber" placeholder="外线号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="语音信箱">
              <el-form :model="voiceMail" size="small" label-width="90px">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="状态">
                      <el-switch v-model="voiceMail.status"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="语言">
                      <el-radio-group v-model="voiceMail.from">
                        <el-radio label="汉语"></el-radio>
                        <el-radio label="英语"></el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="密码">
                      <el-input v-model="voiceMail.password" placeholder="密码为小于六位的数字" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="等待时间(s)">
                      <el-input v-model="voiceMail.latencyTime" placeholder="等待时间(s)" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item label="留言提示音">
                      <el-select v-model="voiceMail.department" placeholder="请选择留言提示音">
                        <el-option label="1" value="type1"></el-option>
                        <el-option label="2" value="type2"></el-option>
                        <el-option label="3" value="type3"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs> -->

      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addStation
} from '@/http/extension.api.js';
import {
  simpleDepartment,
} from '@/http/group.api.js';
import {
  simpleTrunk
} from '@/http/trunk.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'systemAddMenu',
  emits: ["print"],
  setup(props, context) {
    const state        = reactive({
      isShowDialog: false,
      trunk: '',
      ruleForm: {
        dn: '',
        password: '',
        display_name: '',
        duty: '',
        department: '',
        call_authority: '',
        isRecord: 0,
        type: '0',
        status: 0,
        parentId: '',
        trunk_id: '',
        trunk_number: '',
      },
      defaultProps: {
        children: 'children',
        label: 'name',
        value: "id",
        checkStrictly: true,
      },
      data: [],
    });
    const validateFrom = ref()
    const {t}          = useI18n()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        dn: [{
          required: true,
          message: t("请输入分机号"),
          trigger: "blur"
        },
          {
            pattern: /^[0-9]\d*$/,
            message: t('分机号只允许添加数字'),
            trigger: 'blur',
          },
        ],
        password: [{
          required: true,
          message: t("请输入分机密码"),
          trigger: "blur"
        },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,8}$/,
            message: t('分机密码限制6到8位且必须包含大小写字母和数字'),
            trigger: 'blur',
          }],
        call_authority: [{
          required: true,
          message: t("请选择权限"),
          trigger: "change"
        }],
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
      simpleDepartment().then(res => {
        if (res.code == 200) {
          state.data = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
      simpleTrunk().then(res => {
        state.trunk = res.data;
      }).catch(err => {
        console.log(err, 'err');
      });
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit     = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            dn: state.ruleForm.dn,
            password: state.ruleForm.password,
            display_name: state.ruleForm.display_name,
            call_authority: state.ruleForm.call_authority,
            type: state.ruleForm.type,
            status: state.ruleForm.status,
            duty: state.ruleForm.duty,
            is_record: state.ruleForm.isRecord,
            department_id: state.ruleForm.parentId[state.ruleForm.parentId.length - 1],
          };
          addStation(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    }
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm     = () => {
      state.ruleForm.dn             = '';
      state.ruleForm.password       = '';
      state.ruleForm.display_name   = '';
      state.ruleForm.duty           = '';
      state.ruleForm.department     = '';
      state.ruleForm.call_authority = '';
      state.ruleForm.record         = false;
      state.ruleForm.type           = '0';
      state.ruleForm.status         = 0;
      state.ruleForm.isRecord       = 0;
      state.ruleForm.parentId       = '';
      state.ruleForm.trunk_id       = '';
      state.ruleForm.trunk_number   = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}
</style>
