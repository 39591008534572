<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('批量添加分机')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
               ref="validateFrom"
               :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('分机')" prop="dn_start">
              <el-input v-model="ruleForm.dn_start" :placeholder="$t('分机')" maxlength="8" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('创建数量')" prop="dn_count">
              <el-input v-model="ruleForm.dn_count" :placeholder="$t('创建数量')" maxlength="3" onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('密码')" prop="password">
              <el-input v-model="ruleForm.password" :placeholder="$t('密码')" maxlength="16" show-password clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('权限')" prop="call_authority">
              <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择权限')">
                <el-option :label="$t('禁止')" value="0"></el-option>
                <el-option :label="$t('局内')" value="1"></el-option>
                <el-option :label="$t('本地')" value="2"></el-option>
                <el-option :label="$t('国内')" value="4"></el-option>
                <el-option :label="$t('国际权限')" value="8"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item :label="$t('中继')">-->
<!--              <el-select v-model="ruleForm.trunk_id" filterable clearable :placeholder="$t('请选择中继')">-->
<!--                <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item :label="$t('出局外显')">-->
<!--              <el-input v-model="ruleForm.trunk_number" :placeholder="$t('出局外显')"-->
<!--                        :disabled="ruleForm.trunk_id == ''" maxlength="32" clearable></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('录音')">
              <el-switch v-model="ruleForm.record" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio label="0">{{ $t('普通分机') }}</el-radio>
                <el-radio label="1">{{ $t('队列分机') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed,
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  batchaddStation
} from '@/http/extension.api.js';
import {
  simpleTrunk
} from '@/http/trunk.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'systemAddMenu',
  emits: ["print"],
  setup(props, context) {
    const state        = reactive({
      isShowDialog: false,
      trunk: '',
      ruleForm: {
        dn_start: '',
        dn_count: '',
        password: '',
        call_authority: '',
        type: '0',
        record: 0,
        status: 0,
        trunk_id: '',
        trunk_number: '',
      },
    });
    const validateFrom = ref()
    const {t}          = useI18n()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        dn_start: [{
          required: true,
          message: t("请输入分机号"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('分机号只允许添加数字'),
            trigger: 'blur',
          }],
        dn_count: [{
          required: true,
          message: t("请输入分机数量"),
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: t("请输入分机密码"),
          trigger: "blur"
        },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,8}$/,
            message: t('分机密码限制6到8位且必须包含大小写字母和数字'),
            trigger: 'blur',
          }],
        call_authority: [{
          required: true,
          message: t("请选择权限"),
          trigger: "change"
        }],
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
      simpleTrunk().then(res => {
        state.trunk = res.data;
      }).catch(err => {
        console.log(err, 'err');
      });
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit     = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          const params = {
            dn_start: state.ruleForm.dn_start,
            dn_count: state.ruleForm.dn_count,
            password: state.ruleForm.password,
            call_authority: state.ruleForm.call_authority,
            type: state.ruleForm.type,
            is_record: state.ruleForm.record,
            status: state.ruleForm.status,
            trunk_id: state.ruleForm.trunk_id,
            trunk_number: state.ruleForm.trunk_number,
          };
          batchaddStation(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm     = () => {
      state.ruleForm.dn_start       = '';
      state.ruleForm.dn_count       = '';
      state.ruleForm.password       = '';
      state.ruleForm.call_authority = '';
      state.ruleForm.type           = '0';
      state.ruleForm.status         = 0;
      state.ruleForm.trunk_id       = '';
      state.ruleForm.trunk_number   = '';
      state.ruleForm.record         = false;
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      rules,
      validateFrom,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}
</style>
