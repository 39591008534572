<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('编辑分机')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本设置')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="100px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('分机号')" prop="dn">
                  <el-input v-model="ruleForm.dn" :placeholder="$t('分机号')" onkeyup="value=value.replace(/[^\d]/g,'')"
                            maxlength="8" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('分机密码')" prop="password">
                  <el-input v-model="ruleForm.password" :placeholder="$t('分机密码')" maxlength="16" show-password
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="displayName">
                  <el-input v-model="ruleForm.display_name" :placeholder="$t('名称')" maxlength="16"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('职务')">
                  <el-input v-model="ruleForm.duty" :placeholder="$t('职务')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('中继')" prop="trunkId">-->
              <!--                  <el-select v-model="ruleForm.trunkId" filterable clearable :placeholder="$t('请选择中继')">-->
              <!--                    <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('出局外显')">-->
              <!--                  <el-input v-model="ruleForm.trunkNumber" :placeholder="$t('出局外显')" :disabled="ruleForm.trunkId === ''" maxlength="32" clearable></el-input>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('部门')">
                  <el-cascader v-model="ruleForm.departmentId" :options="data" :props="defaultProps"
                               :show-all-levels="false" clearable filterable popper-append-to-body/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('权限')" prop="call_authority">
                  <el-select v-model="ruleForm.call_authority" :placeholder="$t('请选择权限')">
                    <el-option :label="$t('禁止')" :value="0"></el-option>
                    <el-option :label="$t('局内')" :value="1"></el-option>
                    <el-option :label="$t('本地')" :value="2"></el-option>
                    <el-option :label="$t('国内')" :value="4"></el-option>
                    <el-option :label="$t('国际权限')" :value="8"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('录音')">
                  <el-switch v-model="ruleForm.is_record" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('类型')" prop="type">
                  <el-radio-group v-model="ruleForm.type">
                    <el-radio :label="0">{{ $t('普通分机') }}</el-radio>
                    <el-radio :label="1">{{ $t('队列分机') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer">
							<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
							<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
						</span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('高级设置')" name="second">
          <el-tabs tab-position="left" v-model="leftPosition">
            <el-tab-pane :label="$t('无条件转接')" name="leftFirst">
              <el-form :model="noCondition" size="small" label-width="132px" label-position="top">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('状态')">
                      <el-switch v-model="noCondition.status" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('等待时间s')">
                      <el-input-number v-model="noCondition.wait_time" :placeholder="$t('等待时间s')" disabled
                                       maxlength="3" :min="0" :max="999"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('转接号码')">
                      <el-input v-model="noCondition.to_number" :placeholder="$t('转接号码')" maxlength="32" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('呼转外显')">
                      <el-input v-model="noCondition.show_number" :placeholder="$t('呼转外显')" maxlength="32"
                                onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('时间条件')">
                      <el-select v-model="noCondition.time_id" filterable clearable :placeholder="$t('请选择时间条件')">
                        <el-option v-for="item in Time" :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <span class="dialog-footer">
								<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
								<el-button type="primary" @click="noCondSubmit" size="small">{{ $t('保存') }}</el-button>
							</span>
            </el-tab-pane>
            <el-tab-pane :label="$t('无应答转接')" name="leftSecond">
              <el-form :model="response" size="small" label-width="132px" label-position="top">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('状态')">
                      <el-switch v-model="response.status" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('等待时间s')">
                      <el-input-number v-model="response.wait_time" :placeholder="$t('等待时间s')" clearable
                                       maxlength="3" :min="0" :max="999"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('转接号码')">
                      <el-input v-model="response.to_number" :placeholder="$t('转接号码')" maxlength="32" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('呼转外显')">
                      <el-input v-model="response.show_number" :placeholder="$t('呼转外显')" maxlength="32"
                                onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('时间条件')">
                      <el-select v-model="response.time_id" filterable clearable :placeholder="$t('请选择时间条件')">
                        <el-option v-for="item in Time" :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <span class="dialog-footer">
								<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
								<el-button type="primary" @click="reonSubmit" size="small">{{ $t('保存') }}</el-button>
							</span>
            </el-tab-pane>
            <el-tab-pane :label="$t('遇忙转接')" name="leftThird">
              <el-form :model="busy" size="small" label-width="132px" label-position="top">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('状态')">
                      <el-switch v-model="busy.status" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('等待时间s')">
                      <el-input-number v-model="busy.wait_time" :placeholder="$t('等待时间s')" clearable maxlength="3"
                                       :min="0" :max="999"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('转接号码')">
                      <el-input v-model="busy.to_number" :placeholder="$t('转接号码')" maxlength="32" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('呼转外显')">
                      <el-input v-model="busy.show_number" :placeholder="$t('呼转外显')" maxlength="32"
                                onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('时间条件')">
                      <el-select v-model="busy.time_id" filterable clearable :placeholder="$t('请选择时间条件')">
                        <el-option v-for="item in Time" :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <span class="dialog-footer">
								<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
								<el-button type="primary" @click="onBusy" size="small">{{ $t('保存') }}</el-button>
							</span>
            </el-tab-pane>
            <!-- <el-tab-pane :label="$t('外线号码')">
              <el-form :model="perimeter" size="small" label-width="90px">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
                    <el-form-item :label="$t('中继')">
                      <el-select v-model="perimeter.trunkId" filterable clearable :placeholder="$t('请选择中继')">
                        <el-option v-for="item in trunk" :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20" v-if="ruleForm.sysnumber_id !== ''">
                    <el-form-item :label="$t('出局外显')">
                      <el-input v-model="perimeter.trunkNumber" :placeholder="$t('出局外显')" clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <span class="dialog-footer">
                <el-button @click="onCancel" size="small">{{$t('取消')}}</el-button>
                <el-button type="primary" @click="onPerimeter" size="small">{{$t('保存')}}</el-button>
              </span>
            </el-tab-pane> -->
            <el-tab-pane :label="$t('语音信箱')" name="leftFourth">
              <el-form :model="voiceMail" size="small" label-width="132px" label-position="top" label>
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('状态')">
                      <el-switch v-model="voiceMail.status" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                  </el-col>
                  <!--									<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
                  <!--										<el-form-item :label="$t('语言')">-->
                  <!--											<el-radio-group v-model="voiceMail.lan">-->
                  <!--												<el-radio label="cn" :value="cn">{{$t('中文')}}</el-radio>-->
                  <!--												<el-radio label="es" :value="es">{{$t('英文')}}</el-radio>-->
                  <!--											</el-radio-group>-->
                  <!--										</el-form-item>-->
                  <!--									</el-col>-->
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('等待时间s')">
                      <el-input-number v-model="voiceMail.timeout" :placeholder="$t('等待时间s')" clearable
                                       maxlength="3" :min="0" :max="999"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('密码')">
                      <el-input v-model="voiceMail.pwd" :placeholder="$t('密码为小于六位的数字')" maxlength="6"
                                clearable
                                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="$t('留言提示音')">
                      <el-select v-model="voiceMail.greeting" :placeholder="$t('请选择留言提示音')">
                        <el-option v-for="item in media" :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <span class="dialog-footer">
								<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
								<el-button type="primary" @click="onVoiceMail" size="small">{{ $t('保存') }}</el-button>
							</span>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from 'vue-i18n';
// import { setBackEndControlRefreshRoutes } from "/@/router/backEnd";
import {
  findStation,
  updateStation,
  updateTransfer,
  // updateAni,
  updateVoice,
} from "@/http/extension.api.js";
import {
  simpleTrunk
} from '@/http/trunk.api.js';
import {
  ElNotification
} from 'element-plus';
import {
  simpleDepartment,
} from '@/http/group.api.js';
import {
  simpleMedia,
} from '@/http/media.api.js';
import {
  simpleTime,
} from '@/http/setUp.api.js';

export default {
  name: 'systemEditMenu',
  emits: ["print"],
  setup(props, context) {
    const {t}             = useI18n();
    const state           = reactive({
      isShowDialog: false,
      id: '',
      parentId: '',
      ruleForm: {
        dn: '',
        password: '',
        display_name: '',
        duty: '',
        department: '',
        call_authority: '',
        is_record: '',
        type: '',
        status: '',
        departmentId: '',
        trunkId: '',
        trunkNumber: '',
      },
      noCondition: {
        status: 0,
        type: 3,
        to_number: '',
        show_number: '',
        wait_time: 0,
        time_id: '',
      },
      response: {
        status: 0,
        type: 1,
        to_number: '',
        show_number: '',
        wait_time: 0,
        time_id: '',
      },
      busy: {
        status: 0,
        type: 2,
        to_number: '',
        show_number: '',
        wait_time: 0,
        time_id: '',
      },
      perimeter: {
        trunkId: '',
        trunkNumber: '',
      },
      voiceMail: {
        status: 0,
        lan: '',
        pwd: '',
        timeout: 0,
        greeting: '',
      },
      defaultProps: {
        children: 'children',
        label: 'name',
        value: "id",
        checkStrictly: true,
      },
      activeName: 'first',
      leftPosition: 'leftFirst',
      tabPosition: 'left',
      data: [],
      trunk: [],
      media: [],
      Time: [],
    });
    const StationEditData = (state) => {
      let params = {
        id: state.id
      }
      findStation(params).then(res => {
        if (res.code == 200) {
          state.ruleForm = res.data;
          state.parentId = res.data.departmentId;
          // state.perimeter.trunkNumber = res.data.trunkNumber
          // state.perimeter.trunkId = res.data.trunkId
          if (res.data.properties.fwd[1]) {
            state.response = res.data.properties.fwd[1]
          } else {
            state.response.status      = ''
            state.response.to_number   = ''
            state.response.show_number = ''
            state.response.wait_time   = 0
            state.response.time_id     = ''
          }
          if (res.data.properties.fwd[2]) {
            state.busy = res.data.properties.fwd[2]
          } else {
            state.busy.status      = ''
            state.busy.to_number   = ''
            state.busy.show_number = ''
            state.busy.wait_time   = 0
            state.busy.time_id     = ''
          }
          if (res.data.properties.fwd[3]) {
            state.noCondition = res.data.properties.fwd[3]
          } else {
            state.noCondition.status      = ''
            state.noCondition.to_number   = ''
            state.noCondition.show_number = ''
            state.noCondition.wait_time   = 0
            state.noCondition.time_id     = ''
          }
          if (res.data.properties.voicemail.length !== 0) {
            state.voiceMail = res.data.properties.voicemail
          } else {
            state.voiceMail.status   = ''
            state.voiceMail.pwd      = ''
            state.voiceMail.timeout  = 0
            state.voiceMail.greeting = ''
            state.voiceMail.lan      = ''
          }
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      });
      simpleTrunk().then(res => {
        state.trunk = res.data;
      }).catch(err => {
        console.log(err, 'err');
      });
      simpleMedia().then(res => {
        state.media = res.data;
      }).catch(err => {
        console.log(err, 'err');
      });
      simpleTime().then(res => {
        state.Time = res.data;
      }).catch(err => {
        console.log(err, 'err');
      });
    }
    const validateFrom    = ref()
    const rules           = computed(() => {
      const rule = {
        dn: [{
          required: true,
          message: t("请输入分机号"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('分机号只允许添加数字'),
            trigger: 'blur',
          }],
        password: [{
          required: true,
          message: t("请输入分机密码"),
          trigger: "blur"
        },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,8}$/,
            message: t('分机密码限制6到8位且必须包含大小写字母和数字'),
            trigger: 'blur',
          }],
        call_authority: [{
          required: true,
          message: t("请选择权限"),
          trigger: "change"
        }],
        type: [{
          required: true,
          message: t("请选择类型"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog      = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      state.activeName   = 'first';
      state.leftPosition = 'leftFirst';
      StationEditData(state);
      simpleDepartment().then(res => {
        if (res.code == 200) {
          state.data = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          })
        }
      })
    };
    // 关闭弹窗
    const closeDialog     = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel        = () => {
      closeDialog();
      initForm();
    };
    // 基本设置编辑
    const onSubmit        = () => {
      let estimate = null;
      if (state.ruleForm.departmentId != null) {
        if (state.ruleForm.departmentId == state.parentId) {
          estimate = state.ruleForm.departmentId
        } else {
          estimate = state.ruleForm.departmentId[state.ruleForm.departmentId.length - 1]
        }
      } else {
        estimate = '';
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            id: state.id,
            dn: state.ruleForm.dn,
            password: state.ruleForm.password,
            display_name: state.ruleForm.display_name,
            call_authority: state.ruleForm.call_authority,
            type: state.ruleForm.type,
            status: state.ruleForm.status,
            duty: state.ruleForm.duty,
            is_record: state.ruleForm.is_record,
            department_id: estimate,
            trunk_id: state.ruleForm.trunkId,
            trunk_number: state.ruleForm.trunkNumber,
          };
          updateStation(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              // validateFrom.value.resetFields();
              // state.isShowDialog= false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };
    //无条件转接编辑
    const noCondSubmit    = () => {
      const params = {
        id: state.id,
        status: state.noCondition.status,
        type: state.noCondition.type,
        to_number: state.noCondition.to_number,
        show_number: state.noCondition.show_number,
        wait_time: state.noCondition.wait_time,
        time_id: state.noCondition.time_id,
      };
      updateTransfer(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog= false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    //无应答转接编辑
    const reonSubmit      = () => {
      const params = {
        id: state.id,
        status: state.response.status,
        type: state.response.type,
        to_number: state.response.to_number,
        show_number: state.response.show_number,
        wait_time: state.response.wait_time,
        time_id: state.response.time_id,
      };
      updateTransfer(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog= false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    //遇忙转接编辑
    const onBusy          = () => {
      const params = {
        id: state.id,
        status: state.busy.status,
        type: state.busy.type,
        to_number: state.busy.to_number,
        show_number: state.busy.show_number,
        wait_time: state.busy.wait_time,
        time_id: state.busy.time_id,
      };
      updateTransfer(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          // state.isShowDialog= false;
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };

    //外线号码编辑
    // const onPerimeter = () => {
    // 	const params = {
    // 		id: state.id,
    // 		trunk_id: state.perimeter.trunkId,
    // 		trunk_number: state.perimeter.trunkNumber,
    // 	};
    // 	updateAni(params).then(res => {
    // 		if (res.code == 200) {
    // 			ElNotification({
    // 				title: "成功",
    // 				message: "外线号码编辑成功",
    // 				type: 'success',
    // 				duration: 2000,
    // 			});
    // 			// state.isShowDialog= false;
    // 			context.emit("print");
    // 		} else {
    // 			ElNotification({
    // 				title: '失败',
    // 				message: res.msg,
    // 				type: 'error',
    // 				duration: 2000,
    // 			});
    // 		}
    // 	})
    // };

    //语音信箱编辑
    const onVoiceMail = () => {
      const params = {
        id: state.id,
        status: state.voiceMail.status,
        lan: state.voiceMail.lan,
        pwd: state.voiceMail.pwd,
        timeout: state.voiceMail.timeout,
        greeting: state.voiceMail.greeting,
      };
      updateVoice(params).then(res => {
        if (res.code == 200) {
          ElNotification({
            title: t("成功"),
            message: t("编辑成功"),
            type: 'success',
            duration: 2000,
          });
          context.emit("print");
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.dn             = '';
      state.ruleForm.password       = '';
      state.ruleForm.display_name   = '';
      state.ruleForm.duty           = '';
      state.ruleForm.department     = '';
      state.ruleForm.call_authority = '';
      state.ruleForm.record         = false;
      state.ruleForm.type           = '';
      state.ruleForm.status         = 0;
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      noCondSubmit,
      reonSubmit,
      onBusy,
      onVoiceMail,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-divider--horizontal {
  margin-top: 15px;
}

.dialog-footer {
  float: right;
}
</style>
